<template>
  <div class="mister">
      <div class="uk-container uk-margin-xlarge-top">
          <div class="uk-card uk-card-default">
              <div class="uk-card-body">
                  <div class="uk-card-title">
                      <h3>Playground page</h3>
                  </div>

                  <div class="content">
                      <form-button icon="check" @click="doPlayground">בדיקה</form-button>
                  </div>
              </div>
          </div>
      </div>


  </div>
</template>

<script>
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';
import {useStore} from "vuex";


export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },
    data(){
        return {}
    },
    methods: {
        async doPlayground() {
            console.log('do');
            try {
                let result = await this.asyncOps.asyncCall('playground');
            } catch (e) {
                console.log(e);
            }

            console.log('result', result);
        }
    }
}
</script>
